/* Login css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center; /* Center content inside the container */
  margin-top: 10rem;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400;
  text-align: left;
  margin-top: 2rem;
  padding: 0 3rem;
}
.subTitle {
  text-align: left;
  margin: 0.25rem 1.5rem 0 !important;
}

.subTitle2 {
  text-align: left;
  margin: 0.25rem 0 0 1.5rem !important;
}

.linkbox {
  display: flex;
  align-items: center; /* Vertically align items */
  gap: 0.2rem; /* Add space between elements */
  margin-top: 1rem;
}

.linkbox2 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.link {
  color: #4b2354; /* Adjust the link color */
  cursor: pointer; /* Add a pointer cursor */
  text-decoration: underline; /* Optional: underline the link */
}
