$primary-color: #510f5b;

/* ReusableListView.scss */
.core-list {
  margin-bottom: 2rem !important;
}

.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #4499b6 !important;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start !important;
    padding: 12px;
  }

  &__avatar {
    display: flex;
    align-content: center;

    .MuiAvatar-root {
      width: 114px;
      height: 72px;
      border-radius: 4px;
      margin-right: 16px;

      @media (max-width: 600px) {
        width: 100px;
        height: 64px;
        margin-right: 0;
        margin-bottom: 8px;
        margin-right: 0;
      }
    }
  }

  &__details {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 600px) {
      width: 100%;
    }

    .MuiTypography-subtitle1 {
      font-size: 1rem;
    }

    .MuiTypography-body2 {
      font-size: 0.875rem;
      color: #666;
      white-space: normal;
      word-wrap: break-word;
    }
  }

  &__action {
    position: absolute;
    top: 16px;
    right: 16px;

    @media (max-width: 600px) {
      position: relative;
      top: auto;
      right: auto;
      align-self: flex-start;
    }
  }
}

.load-more {
  text-align: right;
  margin-top: 16px;
}

.list-toy-btn {
  display: flex;
  justify-content: end;
  margin: 1rem 0;
  @media (max-width: 600px) {
    margin-right: 3rem;
  }
}

//Action Popup
.dialog-content {
  background-color: #d0e8f2; // Light blue background
  border-radius: 4px;
  padding: 2rem !important;
  text-align: left;
  min-height: 150px; // Change 'height' to 'min-height' to allow flexibility
  max-width: 400px; // Prevent it from becoming too wide
}

.dialog-description {
  margin: 16px 0 !important;
}

.dialog-action-box {
  display: flex;
  justify-content: end;
  gap: 16px;
  margin-top: 28px;
}

.dialog-btns {
  color: $primary-color !important; // Purple
  font-weight: 500 !important;
  font-size: 1rem !important;
  text-transform: none !important;
}

.icon-btn {
  color: $primary-color !important;
}

.first-field {
  color: #3f4948 !important;
  display: block;
  font-size: 0.875rem !important;
}

.second-field {
  color: $primary-color !important;
  font-size: 0.875rem !important;
  display: inline-block;
}
