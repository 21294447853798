$primary-color: #510f5b;
$white-color: #ffffff;

.edit-children-box {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  width: 80%;
}

.add-photo-btn {
  margin-left: 1rem !important;
  color: $primary-color !important;
}

.photo-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.avatar-box {
  width: 40px !important;
  height: 40px !important;
  margin-right: 1rem;
  background-color: $primary-color !important;
  color: $white-color !important;
}
