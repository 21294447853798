$primary-color: #510f5b;

.addChildrenBtn {
  color: $primary-color !important;
}

/* Children Step */
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  min-height: 36px;
}

.successTextLabel {
  margin-bottom: 20px;
}

.resendOtpBtn {
  text-transform: none !important;
  text-decoration: none !important;
  padding: 0 !important;
  min-width: auto !important;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer !important;
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  color: black !important;

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }
}
