$primary-color: #510f5b;

.heading {
  font-size: 34px!important;
  font-weight: 700!important;
  line-height: 42.16px!important;
  letter-spacing: -0.02em!important;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media (max-width: 600px) {
    font-size: 24px!important;
    line-height: 32px!important;
  }
}

.subHeading {
  font-size: 28px!important;
  font-weight: 400!important;
  line-height: 36px!important;
  letter-spacing: -0.02em!important;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media (max-width: 600px) {
    font-size: 20px!important;
    line-height: 28px!important;
  }
}

.descriptionText {
  font-size: 16px!important;
  font-weight: 400!important;
  line-height: 24px!important;
  letter-spacing: -0.02em!important;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.button {
  color: $primary-color!important;
  text-transform: uppercase;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.imageContainer {
  width: 100%;
  max-width: 538px;
  margin: 0 auto;
}

.imageCredit {
  line-height: 0.5!important;
}
