$border-color: red;
$primary-color: #510f5b;

.inputWide {
  width: 100%;
}

.inputNarrow {
  width: 50%;
}

/* Assuming you have a CSS file or styled-components setup */
.fullWidthDatePicker {
  width: 100%; /* Ensure the container is full width */
  margin-top: 16px !important;
  margin-bottom: 8px !important;
}

.formRow {
  display: flex;
  flex-wrap: wrap; // Allow wrapping of fields
  margin-bottom: 16px; // Space between rows
}

.fieldContainer {
  margin-right: 16px; // Space between fields
}

// Adjust last child's margin for each row
.formRow > .fieldContainer:last-child {
  margin-right: 0; // No margin for the last field
}

// Responsive styles
@media (max-width: 600px) {
  .fieldContainer {
    width: 100%; // Full width on small screens
  }
}

/* Modal css */

.MuiDialog-root {
  z-index: 1500; /* Ensure the modal appears on top of other elements */
}

.MuiDialog-container {
  position: absolute; /* Position modal correctly */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.3); /* Darken the background */
}

// selected chip delete icon
.chipDeleteIcon {
  color: black !important;
  font-size: 20px !important;
}
